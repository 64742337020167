<template lang="pug">
.thumbnail-rotator
  .thumbnail-rotator__current
    img(:src="current")

  .thumbnail-rotator__list(
    v-if="active && playing")
    .thumbnail-rotator__item(
      v-for="i, k in images"
      :key="k")
      img(:src="i")
    .thumbnail-rotator__remaining(v-if="remaining > 0") +{{ remaining }}
</template>

<script>
import { slice } from 'lodash'

export const MAX_DISPLAYED_ITEMS = 3

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    delay: {
      type: Number,
      default: 600
    },
    playing: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      index: 0,
      interval: null
    }
  },

  computed: {
    active () {
      return this.items.length > 1
    },

    current () {
      return this.items[this.index]
    },

    images () {
      return slice(this.items, 0, MAX_DISPLAYED_ITEMS)
    },

    remaining () {
      return Math.max(this.items.length - MAX_DISPLAYED_ITEMS, 0)
    }
  },

  watch: {
    $route () {
      this.stop()
    },
    playing: {
      immediate: true,
      handler (val) {
        return val
          ? this.start()
          : this.stop()
      }
    }
  },

  methods: {
    start () {
      if (!this.active) return
      this.stop()
      this.interval = setInterval(this.rotate, this.delay)
    },

    stop () {
      if (this.interval) clearInterval(this.interval)
      this.interval = null
      this.index = 0
    },

    rotate () {
      if (!this.active) return
      const next = this.index + 1 >= this.items.length
        ? 0
        : this.index + 1
      this.index = next
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail-rotator {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  gap: 6px;
  user-select: none;
  &__current {
    width: 90%;
    max-width: 240px;
    margin: 0 auto;
    img {
      width: 100%;
      object-fit: contain;
      aspect-ratio: 1;
      display: block;
    }
  }
  &__list {
    display: flex;
    gap: 4px;
    > * {
      min-width: 0;
      flex: 0 36px;
      aspect-ratio: 1;
      border-radius: 4px;
      overflow: hidden;
    }
  }
  &__item {
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &__remaining {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $--color-placeholder;
    color: $--color-text-secondary;
    font-size: 12px;
  }
}
</style>
